<template>
  <app-module-view>
    <template slot="body">
      <app-scale-filter></app-scale-filter>
      <div class="card">
        <div class="card-body">
          <app-preloader v-if="callingAPI"></app-preloader>
          <app-data-table
            :data="scales"
            :totalCount="totalCount"
            :page="page"
            :config="dataTableConfig"
            :limit="30"
            @page-change="setPageAndGetRecords"
          >
          </app-data-table>
        </div>
      </div>
    </template>
  </app-module-view>
</template>

<script>
import ModuleView from '../ModuleView'
import { mapState } from 'vuex'
import Preloader from '../preloader/Preloader'
import ScaleFilter from './ScaleFilter'
import DataTable from '../shared/DataTable'

export default {
  name: 'ScaleList',
  data () {
    return {
      dataLoaded: false,
      dataTableConfig: {
        fields: {
          siteName: this.$t('scale.site'),
          scaleGroup: this.$t('scale.scaleGroup'),
          groupValue: this.$t('scale.groupValue'),
          name: this.$t('scale.name'),
          value: this.$t('scale.value')
        },
        render: {
          groupValue: (groupValue) => {
            return (groupValue * 100) + '%'
          }
        },
        actions: {
          edit: 'scale_edit'
        }
      }
    }
  },
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    scales () {
      return this.$store.getters['scale/list']
    },
    totalCount () {
      return this.$store.getters['scale/totalCount']
    },
    page () {
      return this.$store.getters['scale/page']
    }
  },
  components: {
    appModuleView: ModuleView,
    appPreloader: Preloader,
    appScaleFilter: ScaleFilter,
    appDataTable: DataTable
  },
  methods: {
    getScales () {
      this.$store.dispatch('scale/fetch')
        .then(() => {
          this.dataLoaded = true
        })
    },
    setPageAndGetRecords (page) {
      this.$store.commit('scale/setPage', page)
      this.getScales()
    }
  },
  mounted () {
    this.getScales()
  }
}
</script>

<style lang="scss" scoped>

.table-responsive {
  th, td {
    font-size: 14px
  }
}

</style>
